import Cookies from "js-cookie";
import { API_KEY } from "../../constants/auth";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Logout } from "@mui/icons-material";

const SignoutButton = () => {
  const navigate = useNavigate();

  const handleSignout = () => {
    Cookies.remove(API_KEY);
    navigate("/");
  };

  return (
    <IconButton onClick={handleSignout}>
      <Logout sx={{ color: "white" }} />
    </IconButton>
  );
};

export default SignoutButton;
