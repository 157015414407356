import "./auth.css"
import React, { useEffect, useState } from 'react';
import {FaAt} from 'react-icons/fa'; // Install react-icons library for eye icons
import { validateEmail } from "../../helpers/validation";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api } from "../../helpers/api";
import { resetPasswordEP } from "../../constants/endpoints";
import { Link } from "react-router-dom";

function ResetPassword() {
  const [email, SetEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState("");


  useEffect(() => {
    if (serverError){
      toast.error(serverError)
    } 
  }, [serverError])


  const handleEmail = (event) => {
    let input = event.target.value
    SetEmail(input);
    setEmailError(!validateEmail(input));
  }

  const handleSubmit = (event) => {
    //clear errors
    setServerError("")
    setEmailError("")
    event.preventDefault();
    //confirm that the email is valid
    if (!validateEmail(email)){
       setEmailError("invalid email")
       return;
    }
    //submit to backend
    setIsLoading(true)
    api.post(resetPasswordEP, {
      'email': email
    }).then((_) => {
      setIsLoading(false)
      toast.success("a reset password link has been sent to this email")
      SetEmail("")
    }).catch((error) => {
      setIsLoading(false)
      if (error.response && error.response.status === 404){
        if (error.response.data.error){
          setServerError(error.response.data.error)
        } else {
          setServerError("an error occured, please try again later")
        }
      } else if (error.response && error.response.status === 400) {
        setServerError("invalid email address")
      } else if (error.response && error.response.status === 503){
        setServerError("service is currently unavailable")
      } else if (error.response && error.response.status === 502) {
        setServerError("Gateway Timeout")
      } else {
        setServerError("Something went wrong, please try again later")
      }
    })
  }


  return (
    <div className="signup-page">
      <div className="form-heading">anyproductpagescraper.com</div>
      <div className="signup-form-wrapper">
      <div className="signup-form-container">
      <div className="form-container">
        <form className="signup-form" onSubmit={handleSubmit}>
          <h1 className="form-title">Reset Password</h1>
          <div className="input-container">
            <label htmlFor="email" className="form-label">Email</label>
            <input id="email" type="email" required placeholder="Enter your email"
             onChange={handleEmail}
             style={email ? (emailError ? { border: '1px solid red' } : { border: '1px solid green'}): null}
             />
            <i className="hideShowIcon">
              <FaAt />
            </i>
          </div>
          <button type="submit" className={`submit-button ${isLoading ? "loading-button": ""}`}>
            {!isLoading && "Reset Password"}
          </button>
          <p className="linkContainer">Already Have an Account ? <Link to="/signin">Sign in</Link></p>
        </form>
      </div>
      </div>
      </div>
    </div>
  );
}

export default ResetPassword;
