import React, { useEffect, useRef, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography, Paper } from '@material-ui/core';
import { api } from "../../helpers/api";
import { confirmEmailEP } from '../../constants/endpoints';
import { useParams } from 'react-router-dom';


const VerfiyEmaiTokenComponent = ({ verificationMessage }) => {
  return (
    <div 
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        backgroundColor: '#46e3c3'  // customGreen
      }}
    >
      <div className="verify-form-heading">anyproductpagescraper.com</div>
      <Paper 
        style={{ 
          padding: '30px', 
          width: '100%', 
          maxWidth: '500px', 
          minHeight: '250px',
          textAlign: 'center',
          marginBottom: '50px',
          backgroundColor: '#ecf500'  // customYellow
        }}
      >
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          {verificationMessage}
        </Typography>
      </Paper>
    </div>
  );
};


const VerfiyEmailToken = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const prevTokenRef = useRef();

  const [message, setMessage] = useState(token ? "Email Verification in Progress..." : "Invalid Token");

  useEffect(() => {
    if (token === prevTokenRef.current) {
      return;
    }
    prevTokenRef.current = token; 

    if (!token) {
      toast.error("Token missing in URL.");
      setMessage("Invalid Token");
      return;
    }

  const verifyEmail = () => {
      const endPoint = confirmEmailEP; // Ensure 'confirmEmailEP' is imported or defined in this module
      const headers = {
          'Authorization': `EMAIL-TOKEN ${token}`
      };
      api.put(endPoint, {}, { headers })
          .then(response => {
              toast.success(response.data.message);
              setMessage("Email Verified Successfully! 🎉");
              setTimeout(() => {
                  navigate("/account/request-builder");
              }, 2000);
          })
          .catch(error => {              
              if (error.response) {
                  handleErrorResponse(error.response);
              } else {
                  handleCatchError(error);
              }
          });
  };
  
  

    const handleErrorResponse = (response) => {
      switch (response.status) {
        case 400:
        case 401:
        case 404:
        case 403:
          toast.error('Invalid Token, Please re-verify.');
          setMessage("Invalid or expired token.");
          setTimeout(() => {
              navigate("/confirm-email/request");
          }, 2000);
          break;
        case 409:
          toast.error('Your account is already verified.');
          setMessage("Account is already verified.");
          navigate("/account/signin");
          break;
        default:
          toast.error(response.data.error || 'Unknown error occurred.');
          setMessage("Verification Failed. Please try again.");
      }
    };

    const handleCatchError = (error) => {
      if (!error.response) {
        toast.error('An error occurred, please try again later.');
        setMessage("An error occurred during verification. Please refresh the page to try again.");
      } else {
        const status = error.response.status;
        if (status === 429) {
            toast.error('Rate limit exceeded. Please try again later.');
            setMessage('Too many attempts. Please wait a moment and try again.');
        } else if (status === 409){
          setMessage('account is already verified.')
          setTimeout(() => {
            navigate("/account/signin");
          }, 2000)
        } else {
            toast.error('Server error occurred.');
            setMessage('An error occurred during verification. Please try again later');
        }
      }
    };

    verifyEmail();

  }, [token, navigate]);

return (
    <VerfiyEmaiTokenComponent
      verificationMessage={message}
    />
);
};

export default VerfiyEmailToken;