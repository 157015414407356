//BACKEND
export const resetPasswordEP = "/account/reset-password";
export const signinEP = "/account/signin";
export const signupEP = "/account/signup";
export const createSubscriptionEP = "/subscription/";
export const updateOrGetSubscriptionEP = (priceId) =>`/subscription/${priceId}`;
export const parseEP = "";
export const parseResultEP = (taskId) => `/parse/result/${taskId}`;
export const trialEP = "/parse/trial";
export const trialResultEp = (taskId) => `/parse/trial/result/${taskId}`;
export const captchaEP = "/validate-captcha/";
export const confirmEmailEP = "/account/confirm-email";
export const getAccountDetails = "/account/details";
export const portalSessionEP = "/account/portal-session"
export const getPlansEP = "/subscription/plans"
export const getPlanEp = (planName) => `/subcription/plans/${planName}`