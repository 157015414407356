// ConfirmModal.js
import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '40%',
      left: '60%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      overflow: 'auto',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '5px',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px'  // Provides spacing between elements
    },
    button: {
        padding: '10px 15px',
        borderRadius: '5px',
        border: 'none',
        fontSize: '1rem',
        cursor: 'pointer',
        width: '120px',  // Uniform width for both buttons
    }
};

function PlanModal({ isOpen, onClose, onConfirm, priceId, planId }) {
    return (
        <Modal 
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirmation Modal"
            style={customStyles}
        >
            <h2>Confirm Plan Change</h2>
            <p>You are about to subscribe to a new plan. Are you sure you want to switch plans?</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button 
                    style={{ ...customStyles.button, backgroundColor: '#4CAF50', color: '#fff' }}
                    onClick={() => onConfirm({priceId, planId})}
                >
                    Confirm
                </button>
                <button 
                    style={{ ...customStyles.button, backgroundColor: '#F44336', color: '#fff' }}
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
}

export default PlanModal;
