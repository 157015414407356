import "./auth.css"
import React, { useState, useEffect} from 'react';
import { FaEye, FaEyeSlash, FaAt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store_user_email, store_user_key } from "../../helpers/storage";
import { validateEmail, validatePassword } from "../../helpers/validation";
import DOMPurify from 'dompurify';
import { api, getAPIKey } from "../../helpers/api";
import { signinEP } from "../../constants/endpoints";
import { Link } from "react-router-dom";


function Signin() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverError, setServerError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const loggedLandingPage = "/account/request-builder";


  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (getAPIKey()){
      navigate(loggedLandingPage);
    }
  }, [navigate]) //effect will run only once when the component mounts

  useEffect(() => {
    if (serverError) {
      toast.error(serverError);
      setServerError("");
    }
  }, [serverError]);


  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  }; 

  const handleEmailChange = (e) => {
    let input = e.target.value
    setEmail(input);
    setEmailError(!validateEmail(input));
  }

  const handlePasswordChange = (e) => {
    let input = e.target.value
    setPassword(input);
    setPasswordError(!validatePassword(input));
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "" || password === "") {
      setServerError("Email and password cannot be empty");
      if(email === "") setEmailError(true);
      if(password === "") setPasswordError(true);
      return;
    }
  
    if (!validateEmail(email)) {
      setServerError("Invalid email format");
      setEmailError(true);
      return;
    }
  
    setServerError("");
    setIsProcessing(true);

    // Sanitize the inputs before sending to the server
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedPassword = DOMPurify.sanitize(password);
    
    api.post(signinEP, {
      email: sanitizedEmail,
      password: sanitizedPassword
    })
    .then((response) => {
      setIsProcessing(false);
      let api_key = response.data.api_key;
      let email = response.data.email;
      store_user_key(api_key, rememberMe);
      store_user_email(email);
      navigate(loggedLandingPage);
    })
    .catch((error) => {
      setIsProcessing(false);
      if (error.response) {
        setServerError(error.response?.data?.error || "Error signing in, please try again");
      } else if (error.request) {
        setServerError("Server did not respond, please try again later");
      } else {
        setServerError("Service is currently unavailable, please try again later");
      }
      setEmailError(true); // Set the error state for email input
      setPasswordError(true); // Set the error state for password input
    });
  };
  
  return (
    <div className="signup-page">
      <div className="form-heading">anyproductpagescraper.com</div>
      <div className="signup-form-wrapper">
        <div className="signup-form-container">
          <div className="form-container">
            <form className="signup-form" onSubmit={handleSubmit}>
              <h1 className="form-title">Sign in</h1>
              <div className="input-container">
                <label htmlFor="email" className="form-label">Email</label>
                <input id="email" 
                       type="email" 
                       required 
                       placeholder="Enter your email" 
                       value={email} 
                       onChange={handleEmailChange} 
                       style={email ? (emailError ? { border: '1px solid red'} : { border: '1px solid green'}): null}
                       disabled={isProcessing}
                        />
                <i className="hideShowIcon">
                  <FaAt />
                </i>
              </div>
              <div className="input-container">
                <label htmlFor="password" className="form-label">Password</label>
                <input id="password" 
                       type={passwordShown ? "text" : "password"} 
                       required 
                       placeholder="Enter your password" 
                       value={password} 
                       onChange={handlePasswordChange} 
                       style={password ? (passwordError ? { border: '1px solid red' } : { border: '1px solid green'}) : null}
                       disabled={isProcessing}
                      />
                <i className="hideShowIcon" onClick={togglePasswordVisiblity}>
                  {passwordShown ? <FaEyeSlash /> : <FaEye />}
                </i>
              </div>
              <div className="checkbox-container">
                <input type="checkbox" id="rememberMe" 
                name="rememberMe" value="remember"  onChange={handleRememberMe}/>
                <label htmlFor="rememberMe" className="form-label">Remember me</label>
              </div>
              <button type="submit" className={`submit-button ${isProcessing ? "loading-button" : ""}`} disabled={isProcessing}>
              {!isProcessing && "Sign in"}
              </button>
              <div class="linkContainer">
              <p>Forgot Password <Link to="/reset-password">Click Here</Link></p>
              <p>No account yet ? <Link to="/signup">Click Here</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
