import React, {useState, useEffect, useRef} from "react";
import "./AppPage.css";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, TextField, Card, CardContent, Tooltip } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Cookies from "js-cookie";
import { API_KEY } from "../../constants/auth";
import { CircularProgress } from '@material-ui/core'
import { 
  curlTemplate, goTemplate, javaTemplate, kotlinTemplate, 
  phpTemplate, pythonTemplate, rubyTemplate, rustTemplate, swiftTemplate,cSharpTemplate, NodeJSAxios,
 } from "../../constants/templates";

import 'react-toastify/dist/ReactToastify.css';
import {api, getAuthHeadersFromStorage, scraper_api} from "../../helpers/api";
import { getAccountDetails, parseEP} from "../../constants/endpoints";
import { getParseError} from "../../constants/response";
import {isValidAttributes, isValidURL} from "./helpers/parse";
import CountrySelect from "./Country";
import {CodeBlock, dracula} from 'react-code-blocks'


function RequestBuilder() {
   // List of programming languagesq
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //disable copying
  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault()
    }

    const codeEl = codeRef.current
    codeEl.addEventListener('copy', handleCopy)

    //Clean up th event listener when the component unmounts
    return () => {
      codeEl.removeEventListener('copy', handleCopy)
    };
  }, []);

  const [apiKey, setAPIKey] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [url, setURL] = useState('')
  const [invalidURL, setInvalidURL] = useState(false)
  const [invalidAttributes, setInvalidAttributes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrapeResponse, setScrapeResponse] = useState(null);
  const [scrapeStatusCode, setscrapeStatusCode] = useState(null);
  const [subscriptionText, setSubscriptionText] = useState("");
  const [subscriptionDaysText, setSubscriptionDaysText] = useState("");
  const [CopybuttonText, setCopyButtonText] = useState("Copy to Clipboard");
  const [countryCode, setCountryCode] = useState('');
  const codeRef = useRef(null);

  const handleCountryChange = (code) => {
    setCountryCode(code)
  }

  const handleSubscriptionInfo = () => {
    const headers = getAuthHeadersFromStorage();
    api.get(getAccountDetails, { headers })
      .then((response) => {
        const data = response.data;
        let text = "no active subscription found";
        let dateText = ""
  
        if (data.is_paid) {
          if (data.cancel_at_period_end === true){
            text = 'your subscription will end on';
          } else {
            text = 'your subscription will reset on';
          }
          dateText = data.current_subscription_period_end_date
        } else if (data.is_on_trial) {
          text = 'your trial expires on';
          dateText = data.trial_end_date
        }
        setSubscriptionText(text);
        setSubscriptionDaysText(dateText);
  
      }).catch((_) => {
        setSubscriptionText("")
        setSubscriptionDaysText("")
        // Handle error scenarios (if needed)
      })
  }
  
  const langs = {
    "cURL": curlTemplate,
    "Python": pythonTemplate,
    "NodeJSAxios": NodeJSAxios,
    "Ruby": rubyTemplate,
    "Java": javaTemplate,
    "PHP": phpTemplate,
    "C#": cSharpTemplate,
    "Swift": swiftTemplate,
    "Go": goTemplate,
    "Rust": rustTemplate,
    "Kotlin": kotlinTemplate
  }
  const generateCode = () => {
    if (!selectedLanguage || !url || selectedAttributes.length === 0) return '';

    const templateFunction = langs[selectedLanguage];
    if (typeof templateFunction === 'function') {
      return templateFunction(apiKey, url, selectedAttributes, countryCode);
    } else {
      return '';
    }
  }

  useEffect(() => {
    let key = Cookies.get(API_KEY);
    setAPIKey(key || "");
    //get details
    handleSubscriptionInfo()
  }, [])

  useEffect(() => {
    if (selectedAttributes.length > 0){
      setInvalidAttributes(false)
    }
  }, [selectedAttributes.length]);



  const handleCopyToClipboard = () => {
    if (codeRef.current) {
      const text = codeRef.current.innerText;
  
      if (navigator.clipboard) {
        // Modern Clipboard API
        navigator.clipboard.writeText(text).then(() => {
          setCopyButtonText("Copied!"); // Set button text on success
          setTimeout(() => setCopyButtonText("Copy to Clipboard"), 2000); // Revert after 2 seconds
        }).catch((err) => {
          setCopyButtonText("Failed to copy!"); // Set button text on failure
          setTimeout(() => setCopyButtonText("Copy to Clipboard"), 2000); // Revert after 2 seconds
        });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          setCopyButtonText("Copied!"); // Set button text on success
          setTimeout(() => setCopyButtonText("Copy to Clipboard"), 2000); // Revert after 2 seconds
        } catch (err) {
          setCopyButtonText("Failed to copy!"); // Set button text on failure
          setTimeout(() => setCopyButtonText("Copy to Clipboard"), 2000); // Revert after 2 seconds
        }
        document.body.removeChild(textarea);
      }
    }
  }
  
  const handleLanguageClick = (language) => {
    setSelectedLanguage(language)
  }

  const handleAttributeClick = (attribute) => {
    if (selectedAttributes.includes(attribute.value)){
      setSelectedAttributes(selectedAttributes.filter(attr => attr !== attribute.value))
    } else {
    setSelectedAttributes([...selectedAttributes, attribute.value])
    }
}

  const handleURLChange = (e) => {
    setURL(e.target.value);
    setInvalidURL(
      !isValidURL(e.target.value)
    )
  }
  const options = [
    { value: 'price', label: 'Price' },
    { value: 'currency', label: 'Currency' },
    { value: 'ratings', label: 'Ratings' },
    { value: 'score', label: 'Score' },
    { value: 'image_url', label: 'Image URL' },
    { value: 'title', label: 'Title' },
    { value: 'description', label: 'Description' }
  ];
  
  const attributesRows = [];
  for (let i = 0; i < options.length; i += 6) {
    attributesRows.push(options.slice(i, i + 6));
  }

  const programmingLanguages = [
    "cURL", "Python", "NodeJSAxios", "Ruby", "Java", "PHP",
    "C#", "Swift", "Go", "Rust", "Kotlin"
  ]

  const rows = [];
  for (let i = 0; i < programmingLanguages.length; i += 6) {
    rows.push(programmingLanguages.slice(i, i + 6));
  }


  const handleTryItClick = () => {
    setScrapeResponse(null)
    setscrapeStatusCode(null)

    if (!isValidURL(url)){
      setInvalidURL(true)
    }

    if (!isValidAttributes(selectedAttributes)){
      setInvalidAttributes(true)
    }

    if (!isValidURL(url) || !isValidAttributes(selectedAttributes)){
      return
    }

    const queryString = new URLSearchParams({
      'api_key': apiKey,
      'url': url,
      'attributes': selectedAttributes.join(','), // Assuming 'selectedAttributes' is an array.
      'country_code': 'fr'
    }).toString();
  
    const requestUrl = `${parseEP}?${queryString}`;

    setLoading(true);

    scraper_api.get(
      requestUrl
    ).then((response) => {
      setLoading(false);
      setscrapeStatusCode(response.status);
      setScrapeResponse({
        data: response.data.body,
      });
    }).catch((error) =>{
      setLoading(false);
      if (error.response && error.response.status !== 502){
        setScrapeResponse({data: getParseError(error.response), isJson: true})
        setscrapeStatusCode(error.response.status)
      } else {
        setscrapeStatusCode(500)
        setScrapeResponse({
          data: {
            error : "Our servers are currently unavailable , please contact support or try again later",
          },
        })
      }
        
    })
  };

  return (
    <div style={{ padding: "20px", marginLeft: "320px", minWidth: "800px" }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <span className="tab-title" style={{ marginLeft: '10px' }}>Request Builder</span>
        <div className="icon-text-wrapper">
          <div className="circular-icon">
            <CalendarMonthIcon/>
          </div>
          <div className="text-content">
            <div className="bold-text">{subscriptionText}</div>
            {subscriptionDaysText && <div className="date-text">{subscriptionDaysText}</div>}
          </div>

        </div>
      </div>
      <div style={{ height: "2px", backgroundColor: "rgba(0, 0, 0, 0.2)", marginBottom: "20px" }}></div>
      <h1 className="tab-section-title">Builder</h1>
      <div className="builder-card-container">
        {/* Card 1 */}
        <Card className="builder-card builder-card-1"
        >
        <CardContent>
            <div className="input-field" style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <label  className="input-label">API Key (required)</label>
                <TextField 
                variant="outlined" 
                placeholder="Autofilled" 
                readOnly
                value={apiKey}
                disabled
                sx={{
                    borderRadius: '8px', padding: '8px', '& .MuiOutlinedInput-input': {
                    padding: '8px 12px', // px-4 py-2
                    },
                    width: '100%', // w-full
                }}
                />
            </div>
            <div className="input-field" style={{
              display: 'flex', flexDirection: 'column',
              marginBottom: '10px',
              }}>
                <label className="input-label">URL (required)
                    <Tooltip title="The url you want to scrape">
                        <InfoIcon className="info-icon" fontSize="10px"/>
                    </Tooltip>
                </label>
                <TextField 
                variant="outlined" 
                placeholder="Enter URL" 
                onChange={handleURLChange}
                error={invalidURL}
                sx={{
                  borderRadius: '8px',
                  padding: '8px',
                  '& .MuiOutlinedInput-input': {
                      padding: '8px 12px',
                  },
                  width: '100%',
                }}
                helperText={!isValidURL(url) ? "A valid URL is required" : null} 
                />
            </div>
            <div className="input-field">
                <label className="input-label">Attributes
                  <Tooltip title="The attributes you want to extract">
                        <InfoIcon className="info-icon" fontSize="10px"/>
                  </Tooltip>
                </label>
                <div className="tiles-container">
                      {attributesRows.map((row, rowIndex) => (
                          <div key={rowIndex} className="tiles-row" style={{
                              display: 'flex',
                              gap: '10px',
                              borderRadius: '10px',
                              padding: '5px', // Padding between boundary and buttons
                          }}>
                              {row.map((attribute, index) => (
                                  <Button key={index} className={`tile ${selectedAttributes.includes(attribute.value) ? 'selected' : ''}`}
                                     onClick={() => { handleAttributeClick(attribute) }} style={{
                                      borderRadius: '10px',
                                      padding: '3px 8px', // Smaller padding for smaller buttons
                                      fontSize: '12px', // Smaller font size
                                      textAlign: 'center',
                                      border: invalidAttributes ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.2)',
                                      color: 'black',
                                      fontWeight: 'bold',
                                  }}>
                                      {attribute.label}
                                  </Button>
                              ))}
                          </div>
                      ))}
                  </div>
              </div>

        <div className="select-country">
              <CountrySelect onCountryChange={handleCountryChange}/>
        </div>
        </CardContent>
        </Card>
  
        {/* Card 2 */}
        <Card className="builder-card builder-card-2"
        sx={{borderBottom: 1}}
        >
          <CardContent style={{ flexGrow: 1 }}>
            <h2 className="builder-card-2-input-label">Request cost: 1</h2>
            <div className="tiles-container">
                {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="tiles-row" style={{
                display: 'flex',
                gap: '10px',
                borderRadius: '10px',
                padding: '5px', // Padding between boundary and buttons
                marginBottom: '1px',
            }}>
              {row.map((language, index) => (
                <Button key={index} className={`tile language ${selectedLanguage === language ? 'selected' : ''}`} onClick={() => { handleLanguageClick(language)}} style={{
                    borderRadius: '10px',
                    padding: '3px 8px', // Smaller padding for smaller buttons
                    fontSize: '12px', // Smaller font size
                    textAlign: 'center',
                    color: 'black',
                    fontWeight: 'bold',
                    border: '1px solid rgba(0, 0, 0, 0.2)'
                  }}>
                    {language}
                  </Button>
              ))}
            </div>
          ))}
          </div>
          <div className="code-bash-screen" ref={codeRef}>
              <CodeBlock
                text={generateCode()}
                language="javascript"
                showLineNumbers={false}
                theme={dracula}
              />
          </div>
            <div className="builder-card-2-buttons">
              <Button 
                    variant="contained" 
                    sx={{
                       backgroundColor: '#006400',
                       borderRadius: '7px',
                      color: 'white', mr: 2,
                      '&:hover': {
                        backgroundColor: '#00d2a1',  // Maintain the same background color on hover
                        // Add any other hover styles you want here
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#006400',  // Maintain the same background color when disabled
                      color: 'white',
                  }
                    }} 
                    startIcon={<PlayArrowIcon />}
                    onClick={handleTryItClick}
                    disabled={loading}
                >
                Try it
                </Button>
                <Button 
                    variant="contained"  
                    startIcon={
                        <FileCopyIcon 
                            style={{
                                color: "#d3d3d3",   // Grey borders for the icon.
                                backgroundColor: "transparent"  // Making the icon background transparent.
                            }}
                            
                        />
                    }
                    sx={{
                        fontWeight: 'bold',
                        backgroundColor: '#fff',
                        color: 'black',  // Black text color.
                        borderColor: '#d3d3d3',
                        '&:hover': {
                          backgroundColor: '#fff',  // Maintain the same background color on hover
                          // Add any other hover styles you want here
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#fff',  // Maintain white background when disabled
                        color: 'black',
                        borderColor: '#d3d3d3',
                    }
                    }}
                    onClick={handleCopyToClipboard}
                  >
                    {CopybuttonText}
                  </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="builder-card-container">
      <Card className='builder-card builder-card-3'>
      </Card>
        <Card className="builder-card builder-card-3">
          <CardContent style={{ display: 'flex', flexDirection: 'column', padding: 0, flexGrow: 1 }}>

            {loading && (
              <div style={{ color: '#f5b400', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                <CircularProgress color="inherit"/> {/* Spinner from Material-UI */}
                <span style={{ marginTop: '10px' }}>Scraping in progress</span>
              </div>
            )}

            {(scrapeStatusCode || scrapeResponse) && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px', width: '100%' }}>
                
                {scrapeStatusCode && (
                  <div style={{ alignSelf: 'flex-start', marginBottom: '10px' }}>
                    <span style={{ color: 'black', fontWeight: 'bold' }}>status:</span>
                    <span style={{ color: scrapeStatusCode === 200 ? '#46e3c3' : '#ecf500', fontWeight: 'bold' }}> {scrapeStatusCode}</span>
                  </div>
                )}
                  {scrapeResponse && (
                    <div className="response-bash-screen loading-container">
                      <pre className="code-container">
                        <CodeBlock
                          text={JSON.stringify(scrapeResponse.data, null, 2)}
                          language="json"
                          showLineNumbers={false}
                          theme={dracula}
                        />
                      </pre>
                    </div>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        </div>
    </div>
  );
}

export default RequestBuilder;
