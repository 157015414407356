export const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
  

export const isValidAttributes = (attributes) => {
    // Check if attributes is an array
    if (!Array.isArray(attributes) || attributes.length === 0) {
      return false;
    }
  
    // Check if the array has non-empty members
    for (let i = 0; i < attributes.length; i++) {
      if (!attributes[i] || typeof attributes[i] !== 'string' || attributes[i].trim() === '') {
        return false;
      }
    }
  
    return true;
  }
  