import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useStripe } from '@stripe/react-stripe-js';
import CreateHandleCheckOut from '../../helpers/checkout';
import "./Upgrade.css"
import { api, getAuthHeadersFromStorage } from '../../helpers/api';
import { getAccountDetails, getPlansEP} from '../../constants/endpoints';
import PlanModal from './PlanModal';
import { getPaymentIntent, handlePlanChange, removePaymentIntent} from './helpers/subscriptions';


const handleCheckout = CreateHandleCheckOut();

function PlanColumn({
   title, buttonText, leftText, rightText, price, 
   duration, isPurchasable, onButtonClick,
   isLoading, isCurrentPlan
   }) {
  return (
    <div style={{ 
      flex: 1, 
      border: '1px solid #ccc', 
      borderRadius: '5px', 
      padding: '1rem', 
      margin: '1rem', 
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      minWidth: '400px'
    }}>
      <h3 style={{ 
        fontSize: '1.5rem', 
        textAlign: 'center', 
        color: isPurchasable ? '#007BFF' : '#555'
      }}>
        {title}
      </h3>
      <div style={{ 
        textAlign: 'center', 
        margin: '1rem 0'
      }}>
        <strong style={{ fontSize: '2rem' }}>
          {`€${price}`}
        </strong>
        <p>{duration.toUpperCase()}</p>
      </div>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-between'
      }}>
        <p>{leftText}</p>
        <p>{rightText}</p>
      </div>
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        {isPurchasable && (
        <button
          onClick={onButtonClick}
          style={{ 
              backgroundColor: (!isCurrentPlan && {backgroundColor: isPurchasable ? '#007BFF' : '#ddd'}), 
              color: '#fff', 
              padding: '0.5rem 1rem', 
              borderRadius: '5px', 
              border: 'none', 
              cursor: 'pointer'
          }}
          disabled={isLoading || isCurrentPlan}
          className={`upgrade-button ${isLoading ? "upgrading-button" : isCurrentPlan ? "current-plan-button" : ""}`}
        >
        {!isLoading && (isCurrentPlan ? "Current Plan" : buttonText)}
      </button>
      
        )}
      </div>
    </div>
  );
}

function Plans() {
    const stripe = useStripe();
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState({});
    const [userDetails,setUserDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlanId, setSelectedPlan] = useState(null);
    const [selectedPriceId, setSelectedPriceID] = useState(null);
  
    const shouldOpenModal = useRef(false);
  
    useEffect(() => {
      const headers = getAuthHeadersFromStorage()
      api.get(getAccountDetails, { headers })
      .then(response => {
        setUserDetails(response.data)
      })
      .catch(error => toast.error("Error fetching accountDetails: ", error));
  
      api.get(getPlansEP)
      .then(response => {
        setPlans(response.data)
      })
      .catch(error => {
        toast.error('failed to fetch plans due to error: ', error);
      });
    }, []);

    useEffect(() => {
      const handleIntent = async () => {
        const paymentIntentInfo = getPaymentIntent()   
        if (paymentIntentInfo){
            const intentPlanInfo = JSON.parse(paymentIntentInfo)
            removePaymentIntent();
            // trigger a checkout process
            try {
              await handleCheckout(stripe, intentPlanInfo.stripe_price_id)
            } catch (error) {
              toast.info(error.message);
            }
        }
      }
      handleIntent();
    }, [])

    useEffect(() =>{
      if (selectedPriceId && selectedPlanId && shouldOpenModal.current){
        setIsModalOpen(true);
        shouldOpenModal.current = false;
      }
    }, [selectedPriceId, selectedPlanId, shouldOpenModal.current]);
  
    const onButtonClick = (planId) => {
      const plan = plans.find(p => p.id === planId);
      if (!plan) {
        setIsLoading(prev => ({ ...prev, [planId]: false }));
        toast.error('invalid selection');
        return;
      }
    
      // Set the selected plan details
      setSelectedPlan(planId);
      const priceID = plan.stripe_price_id;
      setSelectedPriceID(priceID);
    

      //if the user is not a paying user, allow them to subscribe otherwise handle the case
      //where they are switching plans
      if (!userDetails.current_plan_price_id){
        //if the user does not have a running subscription, launch the checkout process
        handleCheckout(stripe, priceID)
              .then(() => {
                setIsLoading(prev => ({ ...prev, [planId]: false }));
              })
              .catch((error) => {
                setIsLoading(prev => ({ ...prev, [planId]: false }));
                if (error.status === 400) {
                    toast.info(error.message)
                } else {
                    toast.warning(error.message);
                }
        })
      } else {
        if (userDetails.current_plan_price_id  !== priceID){
          shouldOpenModal.current = true
        }
      }
    };
    
    //handle confirm change
    const handleConfirmCheckout = () => {
      setIsModalOpen(false);
      setIsLoading(prev => ({ ...prev, [selectedPlanId]: true }));
      
      if (!selectedPriceId) {
        setIsLoading(prev => ({ ...prev, [selectedPlanId]: false }));
        return;
      }
      setTimeout(() => {
        handlePlanChange({
          selectedPlanId: selectedPlanId,
          currentSubscriptionId: userDetails.current_subscription_id,
          newPlanPriceId: selectedPriceId,
          stripe: stripe,
          handleCheckout:(stripe, priceID) => handleCheckout(stripe, priceID),
          updateLoadingStatus: (modifier) => {
            setIsLoading(prev => modifier(prev));
        }
        }
        )
    }, 3000);
    };
    
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'flex-start', 
        paddingTop: '10vh',
        minHeight: '100vh',
        backgroundColor: '#f7f7f7'
      }}>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          width: '60%',         
          marginLeft: '20%',
          maxWidth: '800px'
        }}>
          <h2 style={{ 
            marginBottom: '2rem', 
            fontSize: '2.5rem', 
            color: '#333'
          }}>
            PLANS
          </h2>
          <div style={{ 
            display: 'flex', 
            width: '100%',
          }}>
            {plans.map(plan => (
                <PlanColumn
                  key={plan.id}
                  title={plan.name}
                  buttonText="Buy"
                  leftText={`${plan.credits} credits monthly`}
                  rightText={`${plan.concurrent_threads} concurrent threads`}
                  price={parseFloat(plan.price)}
                  duration="Per month"
                  isPurchasable={true}
                  onButtonClick={() => {
                    setIsLoading(prev => ({ ...prev, [plan.id]: true }));
                    onButtonClick(plan.id)
                  }}
                  isLoading={isLoading[plan.id] || false}
                  isCurrentPlan={plan.stripe_price_id === userDetails.current_plan_price_id}
                />
            ))}
          </div>
          <PlanModal 
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false)
              setIsLoading(false)
              setSelectedPlan(null)
              setSelectedPriceID(null)
            }}
            onConfirm={handleConfirmCheckout}
            priceId={selectedPriceId}
            planId={selectedPlanId}
        />
        </div>
      </div>
    );
}
export default Plans;
