export const getParseResponse = (data) => {
    return {
        data: {
            "result": data.body,
            "status": data.status_code
          }
    }
}

export const getParseError = (response) => {
    // const error = data.detail ? data.detail : (data.error ? data.error : null);
    const data = response.data;
    if (data.error){
        const error = data.error;
        return {
            'error': error.description ? error.description : null,
            'slug':  error.name ? error.name : null,
            'code': error.status_code? error.status_code: null
        };
    }
    
    if (data.detail){
        return {
            'error': data.detail,
            'slug': response.statusText,
            'code': response.status
        };
    }

    return {
        'error': 'server returned an invalid response, please try again or contact support',
        'slug': 'invalid response',
        'code': 500
    };
}
