import Signup from "../auth/Signup"
import Signin from "../auth/Signin";

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ResetPassword from "../auth/ResetPassword";
import AppPage from "../app/AppPage";
import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js'
import SendConfirmationEmail from "../verification/SendConfirmationEmail";
import ConfirmEmail from "../verification/ConfirmEmail";
import ConfirmPasswordReset from "../auth/ConfirmResetPassword";
import { REACT_APP_ENVIRONMENT, REACT_APP_STRIPE_PUBLIC_TEST_KEY, REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY } from "../../constants/vars";

const stripeKey = REACT_APP_ENVIRONMENT === 'development'
  ? REACT_APP_STRIPE_PUBLIC_TEST_KEY
  : REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY

const stripePromise = loadStripe(stripeKey);


function Home(){
    return (
    <div className="flex flex-col min-h-screen">
        <Router>
            <Routes>
                <Route path="/" element={<Signin/>}/>
                <Route path="/signup" element={<Signup/>} />
                <Route path="/signin" element={
                <Elements stripe={stripePromise}><Signin/></Elements>
                }/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/account/*" element={<Elements stripe={stripePromise}><AppPage/></Elements>}/>
                <Route path="/confirm-email/request" element={<SendConfirmationEmail/>}/>
                <Route path="/confirm-email/verify/:token" element={<ConfirmEmail/>}/>
                <Route path="/reset-password/:token" element={<ConfirmPasswordReset/>}/>
            </Routes>
        </Router>
    </div>
)
}

export default Home;