import Cookies from "js-cookie";
import { API_KEY } from "../constants/auth";

export const store_user_key = (apiKey, rememberMe) => {
    if (rememberMe && apiKey){
        //Store the API Key in a cokkue with 7-day expiration if remember me is TRUE
        //TODO we need to add secure: True here once `https` is added to the server
        Cookies.set(API_KEY, apiKey, {expires: 7});
    } else {
        //Store the API Key  in a cookie that will expire when the browser closes.
        Cookies.set(API_KEY, apiKey); //remove {'secure': true} requires https
    }
  }



export const store_user_email = (email) => {
    Cookies.set('user_email', email)
}

export const get_user_email = () => {
  return Cookies.get('user_email');
}