import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, ListItemButton, Box, styled } from "@mui/material";
import { Settings, Article, Receipt, Bolt} from "@mui/icons-material";
import RequestBuilder from "./RequestBuilder";
import Documentation from "./Documentation";
import Plans from "./Upgrade";
import Cookies from "js-cookie";
import { Outlet } from 'react-router-dom';
import "./AppPage.css"
import SignoutButton from "./Logout";
import { get_user_email } from "../../helpers/storage";
import {documentation } from "./constants";
import { api, getAPIKey, getAuthHeadersFromStorage } from "../../helpers/api";
import { getAccountDetails } from "../../constants/endpoints";
import { toast } from "react-toastify";
import { getPortalSession } from "./helpers/portal";
import { useLocation } from 'react-router-dom';
import { signOut } from "../../helpers/auth";
import { getPaymentIntent } from "./helpers/subscriptions";


const CenterContainer = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: "50px"
  // justifyContent: 'center',
});

const ROUTES = {
  BILLING: "billing",
  REQUESTBUILDER: "request-builder",
  DOCUMENTATION: "documentation",
  UPGRADE: "upgrade-subscription"
}

const REVERSE_ROUTES = Object.entries(ROUTES).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

const menuLabels = {
  [ROUTES.BILLING]: "Billing",
  [ROUTES.REQUESTBUILDER]: "Request Builder",
  [ROUTES.DOCUMENTATION]: "Documentation",
  [ROUTES.UPGRADE]: "Upgrade Subscription"
};

const MainContent = ({ handleItemClick, menuItems, selected, userName }) => (
  <div className="main-content-wrapper">
  {/* <IconButton onClick={toggleDrawer}>
    <Menu sx={{ color: "#1d1d22" }} />
  </IconButton> */}
  <Drawer className="drawer-fixed" open={true} variant="permanent" PaperProps={
  { style: { backgroundColor: "#1d1d22",
    display: 'flex', 
    flexDirection: 'column',
    width: '300px',
    borderRadius: '0px 30px 30px 0px'

   } }}>
    <List sx={{ pt: 5, pb: 0}}> {/* Reduced padding here */}
      <ListItem>
        <ListItemText primary="anyproductpagescraper.com" className="menu-title"/>
      </ListItem>
    </List>
    <CenterContainer>
      {menuItems.map((item, index) => (
        <Box key={index} sx={{ my: 1, mx: 2, backgroundColor: item.name === selected ? "#ecf500" : "transparent", borderRadius: "10px", ':hover': { backgroundColor: "#ecf500" } }}>
          <ListItemButton onClick={() => handleItemClick(item.name)}>
            <ListItemIcon>
              {React.cloneElement(item.icon, { sx: { color: "white" } })}
            </ListItemIcon>
            <ListItemText primary={item.name} style={{ color: "grey", fontWeight: "bold" }} />
          </ListItemButton>
        </Box>
      ))}
    </CenterContainer>
    <List sx={{ marginTop: 'auto' }}>
      {/* <ListItemButton sx={{ justifyContent: 'center', ml:5 , mb: 8}} onClick={() => handleItemClick('Upgrade Subscription')}>
        <ListItemText primary="Upgrade Subscription" style={{ color: "white", fontWeight: "bold"}} />
      </ListItemButton> */}
      <Divider style={{color: "white"}}/>
      <ListItem sx={{ alignItems: 'center'}}> {/* Adjusted alignment */}
        <ListItemText primary={userName} style={{ color: "white"}} sx={{mr: 18}} />
        <SignoutButton/>
      </ListItem>
    </List>
  </Drawer>
  <div className="content-area">
    <Routes>
      <Route path={"*"} element={<RequestBuilder/>} />
      <Route path={ROUTES.REQUESTBUILDER} element={<RequestBuilder />} />
      <Route path={ROUTES.DOCUMENTATION} element={<Documentation />} />
      <Route path={ROUTES.UPGRADE} element={<Plans/>} />
    </Routes>
    <Outlet />
  </div>
</div>
)

const LoadingScreen = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <h2>Loading...</h2>
  </div>
);


const AppPage = () => {
  // const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(menuLabels[ROUTES.REQUESTBUILDER]);
  const[userName, setUserName] = useState('UserName');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const checkoutSessionId = params.get('checkout');
  // This function encapsulates the logic for displaying the purchase modal
  const showPurchaseModal = (checkoutSessionId, oneTimePurchase, premium) => {
    const lastCheckoutSessionId = localStorage.getItem('lastCheckoutSessionId');

    if (checkoutSessionId && (!lastCheckoutSessionId || checkoutSessionId !== lastCheckoutSessionId)) {
      if (oneTimePurchase === 'True') {
        toast.success("Your one-time purchase was successful!");
      } else if (premium === 'True') {
        toast.success("Your subscription purchase was successful!");
      } else {
        toast.success("Your purchase was successful!"); // Fallback message
      }

      // Save the checkoutSessionId in local storage
      localStorage.setItem('lastCheckoutSessionId', checkoutSessionId);
    }
  };

  //post request error handling function
  const handleUserDetailsError = (error) => {
    if (error.response) {
      toast.error("Invalid user details, please signin and try again");
      // Logout user as the apikey is most likely invalid
      signOut();
      // Redirect to signin
      navigate("/signin");
    } else if (!error.response) {
      toast.error('Unable to fetch user details. Please try again later');
    } else {
      toast.error('An unknown error occurred. Please refresh the page or try again later');
    }
  };
  

  useEffect(() => {
    const referer = document.referrer;

    if (checkoutSessionId && referer.startsWith('https://checkout.stripe.com')) {
      const oneTimePurchase = params.get('one_time_purchase');
      const premium = params.get('premium');
      
      showPurchaseModal(checkoutSessionId, oneTimePurchase, premium);
    }
  
  }, [checkoutSessionId]);
  

  //set selected to match path name
  useEffect(() => {
    const currentPathPart = location.pathname.split('/').pop();
    const currentRouteKey = REVERSE_ROUTES[currentPathPart];
    const menuName = menuLabels[ROUTES[currentRouteKey]];
    if (menuName) {
      setSelected(menuName);
    }
    
  }, [location]);

  useEffect(() => {
    const userNotLoggedIn = !getAPIKey();
    const userEmailStored = Cookies.get('user_email');
  
    if (userNotLoggedIn) {
      navigate("/signin");
      return;
    }
  
    if (userEmailStored) {
      setUserName(get_user_email());
    }
  
    // Fetch user details
    const headers = getAuthHeadersFromStorage();
    setIsLoading(true);
  
    api.get(getAccountDetails, {headers})
      .then(response => {
        const { is_email_verified, email, api_key } = response.data;
        if (!is_email_verified) {
          navigate("/confirm-email/request", {state: { email, apiKey: api_key }});
          return;
        }
        
        if (getPaymentIntent()){
          navigate("/account/upgrade-subscription");
        }
  
        setIsLoading(false);
      })
      .catch(error => {
        handleUserDetailsError(error);
        setIsLoading(false);
      });
  
  }, []);
  




  const handleItemClick = (name) => {
    setSelected(name);
    if (name === menuLabels[ROUTES.DOCUMENTATION]){
      window.open(documentation);
      return
    }

    if (name === menuLabels[ROUTES.BILLING]){
      // TODO we need to add a loading animation here
      const apiKey = getAPIKey()
      getPortalSession(apiKey);
      return;
    }
    navigate(`/account/${name.toLowerCase().replace(' ', '-')}`);
  };

  const menuItems = [
    { name: menuLabels[ROUTES.REQUESTBUILDER], icon: <Settings /> },
    { name: menuLabels[ROUTES.DOCUMENTATION], icon: <Article /> },
    { name: menuLabels[ROUTES.UPGRADE], icon: <Bolt/> },
    { name: menuLabels[ROUTES.BILLING], icon: <Receipt/> }
  ];

  
  return (
    <div className="App">
    {isLoading ? <LoadingScreen /> : <MainContent
    handleItemClick={handleItemClick} menuItems={menuItems}
    selected={selected} userName={userName}
    />}
  </div>
  );
};






export default AppPage;
