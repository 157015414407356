import "./auth.css"
import React, { useEffect, useState } from 'react';
import {FaKey} from 'react-icons/fa';
import {toast } from 'react-toastify';
import { api } from "../../helpers/api";
import { useParams } from "react-router-dom";
import { resetPasswordEP } from "../../constants/endpoints";

function ConfirmPasswordReset() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matchError, setMatchError] = useState("");
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {token} = useParams();

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setMatchError("Passwords do not match");
    } else {
      setMatchError("");
    }

    if (serverError) {
      toast.error(serverError);
    }

  }, [password, confirmPassword, serverError]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!password || !confirmPassword || password !== confirmPassword) {
      setMatchError("Passwords do not match");
      return;
    }

    if (!token){
        toast.error("token not found");
        return;
    }
    const headers = {
        'Authorization': `EMAIL-TOKEN ${token}`
      };
    setIsLoading(true); 

    api.put(resetPasswordEP, {
      'new_password': password
    }, {headers}
    ).then((_) => {
      toast.success("Password updated successfully");
      setPassword("");
      setConfirmPassword("");
    }).catch((error) => {
      // Check if the error has a response and if the status is 403
      if (error.response) {
        if (error.response.status === 403) {
          setServerError("Invalid token");
        } else if (error.response.data && error.response.data.error) {
          setServerError(error.response.data.error);
        } else {
          setServerError("Something went wrong, please try again later");
        }
      } else {
        // If the error doesn't have a response, it's likely a network issue
        setServerError("Network error. Please check your connection and try again.");
      }
    }).finally(() => {
      setIsLoading(false);
    });
    
  }

  return (
    <div className="signup-page">
      <div className="form-heading">anyproductpagescraper.com</div>
      <div className="signup-form-wrapper">
      <div className="signup-form-container">
      <div className="form-container">
        <form className="signup-form" onSubmit={handleSubmit}>
          <h1 className="form-title">Confirm Password Reset</h1>
          <div className="input-container">
            <label htmlFor="password" className="form-label">New Password</label>
            <input id="password" type="password" required placeholder="Enter new password"
             onChange={(e) => setPassword(e.target.value)} 
             />
            <i className="hideShowIcon">
              <FaKey />
            </i>
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
            <input id="confirmPassword" type="password" required placeholder="Confirm new password"
             onChange={(e) => setConfirmPassword(e.target.value)}
             style={password && confirmPassword ? (matchError ? { border: '1px solid red' } : { border: '1px solid green'}): null}
             />
            <i className="hideShowIcon">
              <FaKey />
            </i>
          </div>
          <button type="submit" className={`submit-button ${isLoading ? "loading-button": ""}`}
          disabled={isLoading || matchError || !password || !confirmPassword}>
            {!isLoading && "Confirm Password Reset"}
          </button>
          <p>Remembered? <a href="/signin">Sign in</a></p>
        </form>
      </div>
      </div>
      </div>
    </div>
  );
}

export default ConfirmPasswordReset;
