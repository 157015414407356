import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper } from '@material-ui/core';
import "./styles.css"
import { api } from "../../helpers/api";
import { confirmEmailEP} from '../../constants/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import the CSS for react-toastify

const EmailVerificationComponent = ({ message, emailSent, handleSendEmail, loading }) => {
  return (
    <div 
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        backgroundColor: '#46e3c3'  // customGreen
      }}
    >
      <div className="verify-form-heading">anyproductpagescraper.com</div>
      <Paper 
        style={{ 
          padding: '30px', 
          width: '100%', 
          maxWidth: '500px', 
          minHeight: '250px',
          textAlign: 'center',
          marginBottom: '50px',
          backgroundColor: '#ecf500'  // customYellow
        }}
      >
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          Email Verification
        </Typography>
        {emailSent ? (
          <Typography variant="body1">
            Email verification link sent! Please check your inbox.
          </Typography>
        ) : (
          <>
            <Typography variant="body1" style={{ marginTop: '20px', fontWeight: "bold" }}>
              {message}
            </Typography>
            <Button
              variant="contained"
              style={{ 
                marginTop: '20px', 
                backgroundColor: '#1308a8',  // customBlue
                color: 'white',  // Ensure the text on the button is readable
                position: 'relative',  // To position the loader correctly
                width: '150px',  // You can adjust this to the size you feel is appropriate
                height: '40px',  // You can adjust this to the size you feel is appropriate
              }}
              onClick={handleSendEmail}
              disabled={loading}
            >
              {loading ? <span className="loading-button"></span> : 'Click Here'}
            </Button>
          </>
        )}
      </Paper>
    </div>
  );
};

const SendConfirmationEmail = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Your email is not verified yet. Please check your inbox, or click the button below to receive a verification email.')
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const apiKey = location.state?.apiKey;
  const SIGNIN_EP = "/signin";

  useEffect(() => {
    if (!email) {
      navigate(SIGNIN_EP);
      return;
    }
  }, [email, navigate]);

  useEffect(() => {
    if (!apiKey) {
      navigate(SIGNIN_EP);
      return;
    }
  }, [apiKey]);

const handleSendEmail = async () => {
    setLoading(true);
    try {
        const endPoint = confirmEmailEP;
        const headers = { 'Authorization': `APIKEY ${apiKey}` };
        const body = { 'email': email };
        const response = await api.post(endPoint, body, { headers });

        if (response.status === 200) {
            setEmailSent(true);
            toast.success(`Success: ${response.data.info}`);
        }
    } catch (error) {
        let errorMessage = 'An error occurred, please try again later.';

        if (error.response) {
          handleErrorResponse(error.response);
          return;
        }

        setMessage(errorMessage);

    } finally {
        setLoading(false);
    }
};

const handleErrorResponse = (response) => {
    let errorMessage;
    switch (response.status) {
        case 403:
          errorMessage = "action forbidden, please contact support";
          break;
        case 400:
            errorMessage = response.data.error;
            break;
        case 500:
            errorMessage = 'Failed to send the email. Please try again or contact support.';
            break;
        default:
            errorMessage = 'Unknown error occurred. please contact support';
            break;
    }

    setMessage(errorMessage);
};


  return (
    <EmailVerificationComponent 
      message={message}
      emailSent={emailSent} 
      loading={loading}
      handleSendEmail={handleSendEmail} 
    />
  );
};

export default SendConfirmationEmail
