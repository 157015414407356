import axios from "axios"
import { API_KEY } from "../constants/auth";
import Cookies from "js-cookie";
import { COMPLETED, FAILED} from "../constants/api";

//axios baseurl config
const dev = 'development'
const environment = process.env.REACT_APP_ENVIRONMENT

export const apiBaseURL = environment === dev
  ? process.env.REACT_APP_API_TEST_BASEURL
  : process.env.REACT_APP_API_LIVE_BASEURL;

export const scraperBaseURL = environment === dev
    ? process.env.REACT_APP_SCRAPER_TEST_BASEURL
    : process.env.REACT_APP_API_LIVE_BASEURL

export const api = axios.create(
    {
        baseURL: apiBaseURL
    }
)
export const scraper_api = axios.create(
    {
        baseURL: scraperBaseURL
    }
)
export const getAPIKey = () => {
    return Cookies.get(API_KEY);
}

export const getAuthHeadersFromStorage = ( ) => {
    return {'Authorization': `APIKEY ${getAPIKey()}`}
}


export const pollTaskEndpoint = ({
    requestEndpoint,
    body,
    headers = {},
    onComplete,
    onError,
    onMaxRetriesExceeded,
    pollingInterval = 5000,
    maxRetries = 30,
    resultEndPoint,
}) => {
    api.post(requestEndpoint, body, { headers }).then((res) => {
        const taskId = res.data.task_id;
        let retries = 0;
        const interval = setInterval(() => {
            api.get(resultEndPoint(taskId), { headers })
                .then(taskResult => {
                    if (taskResult.data.status === COMPLETED || taskResult.data.status === FAILED) {
                        clearInterval(interval);
                        onComplete(taskResult);
                        return;
                    } else if (retries >= maxRetries) {
                        clearInterval(interval);
                        onMaxRetriesExceeded();
                        return;
                    }

                        retries++;
                    })
                    .catch(err => {
                        clearInterval(interval);
                        onError(err);
                    });
            }, pollingInterval);
        })
        .catch((err) => {
            onError(err);
        });
}