import { toast } from "react-toastify"
import { portalSessionEP } from "../../../constants/endpoints"
import { api} from "../../../helpers/api"

export const getPortalSession = (apiKey) => {
    if (!apiKey){
        return;
    }

    let headers = {
        'Authorization': `APIKEY ${apiKey}`
    }
    
    api.post(portalSessionEP, {}, {headers})
    .then((response) => {
        let status = response.data.status
        if (status === "info"){
            toast.info(response.data.message)
            return
        }
        let session = response.data.session;
        window.location.href = session.url;

    }).catch((error) => {
        toast.error(`stripe error: ${error.response.data.error}`);
    });
}
