import "./auth.css";
import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash, FaAt} from 'react-icons/fa'; 
import {useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import { store_user_email, store_user_key } from "../../helpers/storage";
import DOMPurify from 'dompurify';
import { api } from "../../helpers/api";
import { signupEP } from "../../constants/endpoints"
import { Link } from "react-router-dom";


function Signup() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false); // new state
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);
 }, []);
 
  useEffect(() => {
    if (serverError) {
      toast.error(serverError); // using toastify here
      setEmailError(true); // highlighting the email field if server returns an error
      setServerError("");
    }
  }, [serverError]);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  }; 

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleEmailChange = (e) => {
    let input = e.target.value
    setEmail(input);
    setEmailError(!validateEmail(input))
  }

  const handlePasswordChange = (e) =>{
    let input = e.target.value
    setPassword(input)
    setPasswordError(!validatePassword(input))
  }

  const handleSubmit = (event) => {
    event.preventDefault();


    if (!validateEmail(email)) {
      setServerError("Invalid email format");
      return;
    }

    if (!validatePassword(password)) {
      setServerError("Password should be at least 8 characters long");
      return;
    }


    // Sanitize the inputs before sending to the server
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedPassword = DOMPurify.sanitize(password);
  
    setServerError("");
    setIsLoading(true)
    api.post(signupEP, {
      email: sanitizedEmail,
      password: sanitizedPassword
    })
    .then((response) => {
      setIsLoading(false);
      if (response.status === 201) {
        setEmail("");
        setPassword("");
        store_user_key(response.data.api_key, rememberMe);
        store_user_email(response.data.email);
        navigate(`/signin`)
      } else {
        setServerError(response.data.email || "Error signing up, please try again");
      }
    })
    .catch((error) => {
      setIsLoading(false);
    
      if (error.response) {
        let errorMsg;
    
        // Check if the error message is an array and join it into a string
        if (error.response.status === 400){
          errorMsg = "provided email address is not available"
        } else {
          errorMsg = "Error signing up, please try again";
        }
    
        setServerError(errorMsg);
      } else if (error.request) {
        setServerError("We are having trouble connecting to our servers. Please check your internet connection and try again later");
      } else {
        setServerError("An error occurred, please check your connection or try again later");
      }
    });
  };

  return (
    <div className="signup-page">
      <div className="form-heading">anyproductpagescraper.com</div>
      <div className="signup-form-wrapper">
      <div className="signup-form-container">
      <div className="form-container">
        <form className="signup-form" onSubmit={handleSubmit}>
          <h1 className="form-title">Sign up</h1>
          <div className="input-container">
            <label htmlFor="email" className="form-label">Email</label>
            <input id="email" 
                   type="email" 
                   required 
                   placeholder="Enter your email" 
                   value={email} 
                   onChange={handleEmailChange}
                   disabled={isLoading}
                   style={email ? (emailError ? { border: '1px solid red'} : { border: '1px solid green'}): null} /> {/* Added style change */}
            <i className="hideShowIcon">
              <FaAt />
            </i>
          </div>
          <div className="input-container">
            <label htmlFor="password" className="form-label">Password</label>
            <input id="password" 
                   type={passwordShown ? "text" : "password"} 
                   required 
                   placeholder="Enter your password" 
                   value={password} 
                   onChange={handlePasswordChange}
                   disabled={isLoading}
                   style={password ? (passwordError ? { border: '1px solid red' } : { border: '1px solid green'}) : null}
                  />
            <i className="hideShowIcon" onClick={togglePasswordVisiblity}>
              {passwordShown ? <FaEyeSlash /> : <FaEye />}
            </i>
          </div>
          <div className="checkbox-container">
            <input type="checkbox" id="rememberMe" name="rememberMe" value="remember" onChange={handleRememberMe}/>
            <label htmlFor="rememberMe" className="form-label">Remember me</label>
          </div>
          <button type="submit" className={`submit-button ${isLoading ? "loading-button" : ""}`} disabled={isLoading}>
            {!isLoading && "Sign up"}
            </button>
            <p class="linkContainer">Already have an account ? <Link to={`/signin`}>Sign in</Link></p>
        </form>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Signup;
