import {scraperBaseURL } from "../helpers/api";

const endPoint = scraperBaseURL

export const curlTemplate = (apiKey, url, attributes, countryCode) => {
  const queryParams = [
    `api_key=${apiKey}`,
    `url=${encodeURIComponent(url)}`,
    `attributes=${attributes.join(',')}`,
    `country_code=${countryCode}`
  ];
  const fullUrl = `${endPoint}?\\\n${queryParams.join('&\\\n')}`;
  
  return `curl "${fullUrl}"`;
};



export const pythonTemplate = (apiKey, url, attributes, countryCode) => {

  return `import requests
params = {
    'api_key': '${apiKey}',
    'url': '${url.replace("'", "\\'")}',
    'attributes': '${attributes.join(',')}',
    'country_code': '${countryCode}'
}
response = requests.get('${endPoint}', params=params)
print(response.json())
`;
};

export const NodeJSAxios = (apiKey, url, attributes, countryCode) => {
  // The base URL endpoint for the GET request

  // Generate the query string with the required parameters
  const params = new URLSearchParams({
    api_key: apiKey,
    url: url,
    attributes: attributes.join(','),
    country_code: countryCode
  }).toString();

  return `
const axios = require('axios');

async function fetchData(){
  const response = await axios.get('${endPoint}?${params}');
  console.log(response.data);
}

fetchData().catch(error => console.error('Error fetching data:', error));

`
};

export const rubyTemplate = (apiKey, url, attributes, countryCode) => `require 'net/http'
require 'uri'

query_params = {
  'api_key' => '${apiKey}',
  'url' => '${url}',
  'attributes' => '${attributes.join(',')}',
  'country_code' => '${countryCode}'
}.map { |k, v| "\#{k}=\#{URI.encode_www_form_component(v)}" }.join('&')

uri = URI("${endPoint}?\#{query_params}")
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = (uri.scheme == 'https')
response = http.get(uri)
puts response.body
`;

export const javaTemplate = (apiKey, url, attributes, countryCode) => {
  // Use encodeURIComponent to encode URI components
  const encodedApiKey = encodeURIComponent(apiKey);
  const encodedUrl = encodeURIComponent(url);
  const encodedAttributes = attributes.map(attr => encodeURIComponent(attr)).join(',');
  const encodedCountryCode = encodeURIComponent(countryCode);

  return `import java.net.HttpURLConnection;
import java.net.URL;
import java.io.InputStream;
import java.io.InputStreamReader;
import java.io.BufferedReader;
import java.io.IOException;

public class Main {
    private static String readStream(InputStream stream) throws IOException {
        BufferedReader in = new BufferedReader(new InputStreamReader(stream));
        StringBuilder response = new StringBuilder();
        String line;
        while ((line = in.readLine()) != null) {
            response.append(line);
        }
        in.close();
        return response.toString();
    }

    public static void main(String[] args) {
        String apiKey = "${encodedApiKey}";
        String url = "${encodedUrl}";
        String attributes = "${encodedAttributes}";
        String countryCode = "${encodedCountryCode}";

        try {
            URL apiUrl = new URL(String.format("${endPoint}?api_key=%s&url=%s&attributes=%s&country_code=%s",
                    apiKey, url, attributes, countryCode));
            HttpURLConnection con = (HttpURLConnection) apiUrl.openConnection();
            con.setRequestMethod("GET");

            int status = con.getResponseCode();
            String responseContent;

            if (status > 299) {
                responseContent = readStream(con.getErrorStream());
            } else {
                responseContent = readStream(con.getInputStream());
            }

            System.out.println(responseContent);

        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;
};

export const phpTemplate = (apiKey, url, attributes, countryCode) => {

  const attributeList = Array.isArray(attributes) ? attributes.join(',') : attributes;
  const queryParams = `${encodeURIComponent(attributeList)}`;

  return `<?php
$apiUrl = "${endPoint}?api_key=${encodeURIComponent(apiKey)}&url=${encodeURIComponent(url)}&attributes=${queryParams}&country_code=${encodeURIComponent(countryCode)}";
$options = array(
  'http' => array(
    'header' => "Authorization: APIKEY ${apiKey}",
    'method' => 'GET',
    'ignore_errors' => true
  )
);
$context = stream_context_create($options);
$response = @file_get_contents($apiUrl, false, $context);
$responseCode = null;

if (isset($http_response_header)) {
  foreach ($http_response_header as $header) {
    if (preg_match('/^HTTP\\/\\d+\\.\\d+\\s+(\\d+)/', $header, $matches)) {
      $responseCode = (int)$matches[1];
      break;
    }
  }
}

if ($response === FALSE || $responseCode !== 200) {
  if ($response !== FALSE && $responseCode >= 400) {
    echo "Error response body: " . $response;
  } else {
    echo "Error: Failed to make a request or unknown status code.";
  }
} else {
  var_dump($response);
}
?>`;
};


export const swiftTemplate = (apiKey, url, attributes, countryCode) => {
  // Create a comma-separated string from attributes
  const attributeString = attributes.join(',');

  // Encode the entire attributes string
  const encodedAttributes = encodeURIComponent(attributeString);

  // Construct the full URL with query parameters
  const fullUrl = `${endPoint}?api_key=${encodeURIComponent(apiKey)}&url=${encodeURIComponent(url)}&attributes=${encodedAttributes}&country_code=${countryCode}`;

  return `
import Foundation
import FoundationNetworking

let url = URL(string: "${fullUrl}")!
var request = URLRequest(url: url)
request.httpMethod = "GET"

let task = URLSession.shared.dataTask(with: request) { (data, response, error) in
    if let error = error {
        print("Error: \\(error)")
    } else if let data = data, let response = response as? HTTPURLResponse, response.statusCode == 200 {
        let str = String(data: data, encoding: .utf8)
        print(str ?? "Invalid response")
    } else if let response = response as? HTTPURLResponse {
        print("Error: Received status code \\(response.statusCode)")
    }
}

task.resume()
`;
};

export const goTemplate = (apiKey, url, attributes, countryCode) => {
  // Join attributes into a comma-separated string
  const attributeString = attributes.join(',');

  // Construct the query parameters string
  const queryParams = `api_key=${encodeURIComponent(apiKey)}&url=${encodeURIComponent(url)}&attributes=${encodeURIComponent(attributeString)}`;

  // Combine endpoint with queryParams to form the complete GET request URL
  const getUrl = `${endPoint}?${queryParams}&country_code=${countryCode}`;

  return `package main

import (
    "net/http"
    "fmt"
    "log"
    "io"
)

func main() {
    req, _ := http.NewRequest("GET", "${getUrl}", nil)
    client := &http.Client{}
    resp, err := client.Do(req)
    if err != nil {
        log.Fatalf("Error making request: %v", err)
    }
    defer resp.Body.Close()
  
    body, err := io.ReadAll(resp.Body)
    if err != nil {
        log.Fatalf("Error reading response body: %v", err)
    }

    if resp.StatusCode != http.StatusOK {
        fmt.Printf("Received non-200 response: %d\\n%s\\n", resp.StatusCode, string(body))
        return
    }
  
    fmt.Println(string(body))
}`;
};




export const kotlinTemplate = (apiKey, url, attributes, countryCode) => {
  // Create a comma-separated string from the attributes array
  const queryParams = "attributes=" + attributes.map(attr => `${encodeURIComponent(attr)}`).join('&');

  return `import java.net.HttpURLConnection
import java.net.URL
import java.io.BufferedReader
import java.io.InputStreamReader
import java.io.IOException

fun main() {
    val apiUrl = URL("${endPoint}?api_key=${encodeURIComponent(apiKey)}&url=${encodeURIComponent(url)}&${queryParams}&country_code=${countryCode}")
    val connection = apiUrl.openConnection() as HttpURLConnection
    connection.requestMethod = "GET"

    try {
        val responseCode = connection.responseCode
        if (responseCode == HttpURLConnection.HTTP_OK) {
            BufferedReader(InputStreamReader(connection.inputStream, "utf-8")).use { reader ->
                val response = reader.readText()
                println(response)
            }
        } else {
            BufferedReader(InputStreamReader(connection.errorStream, "utf-8")).use { reader ->
                val response = reader.readText()
                println("Error: HTTP response code: $responseCode. Response body: $response")
            }
        }
    } catch (e: IOException) {
        e.printStackTrace()
    } finally {
        connection.disconnect()
    }
}`;
};

export const rustTemplate = (apiKey, url, attributes, countryCode) => {
  // Create query parameters string from attributes and country_code
  const queryParams = 'attributes=' + attributes.map(attr => `${encodeURIComponent(attr)}`).join('&') + `&country_code=${encodeURIComponent(countryCode)}`;

  const tomlComment = `
// Cargo.toml dependencies
/*
[package]
name = "aps"
version = "0.1.0"
edition = "2021"

[dependencies]
reqwest = { version = "0.11", features = ["blocking", "json"] }
serde = { version = "1.0", features = ["derive"] }
*/
`;

  return `${tomlComment}
extern crate reqwest;
use reqwest::blocking::Client;
use reqwest::header::{HeaderMap, HeaderValue, USER_AGENT};
use reqwest::StatusCode;
use std::error::Error;
use std::time::Duration;

fn main() -> Result<(), Box<dyn Error>> {
    let client = Client::builder()
        .timeout(Duration::from_secs(200))
        .build()?;
    let url = format!("${endPoint}?api_key={}&url={}&{}", "${apiKey}", "${url}", "${queryParams}");

    let mut headers = HeaderMap::new();
    headers.insert(USER_AGENT, HeaderValue::from_static("reqwest"));

    let res = client.get(&url)
        .headers(headers)
        .send()?;

    match res.status() {
        StatusCode::OK => {
            println!("{}", res.text()?);
        }
        StatusCode::FORBIDDEN => {
            println!("Access forbidden: {}", res.text()?);
        }
        s => {
            println!("Received response status: {:?}", s);
            println!("{}", res.text()?);
        }
    }

    Ok(())
}`;
};

export const cSharpTemplate = (apiKey, url, attributes, countryCode) => {
  // Create the query string from attributes
  const queryString = 'attributes=' + attributes.map(attr =>  `${encodeURIComponent(attr)}`).join('&');

  return `using System;
using System.Net.Http;
using System.Threading.Tasks;

public class Program {
    public static async Task Main() {
        using (HttpClient client = new HttpClient()) {
            string fullUrl = "${endPoint}?api_key=${apiKey}&url=${encodeURIComponent(url)}&${queryString}&country_code=${countryCode}";

            HttpResponseMessage response = await client.GetAsync(fullUrl);

            if (!response.IsSuccessStatusCode) {
                Console.WriteLine("Error: " + response.StatusCode);
                string responseContent = await response.Content.ReadAsStringAsync();
                Console.WriteLine(responseContent);
                return;
            }

            string responseString = await response.Content.ReadAsStringAsync();
            Console.WriteLine(responseString);
        }
    }
}`;
};
