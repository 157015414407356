import { toast } from "react-toastify"
import {getPlanEp, updateOrGetSubscriptionEP } from "../../../constants/endpoints"
import { api, getAuthHeadersFromStorage } from "../../../helpers/api"

const MAX_RETRIES = 5;
const CANCELLED = 'canceled'

const onMaxRetriesExceeded = () => {
    toast.error('request timeout, please contact support or try again later')
}

const onError = (error) => {
    toast.error('an error occured , please contact support or try again later');
}

export const handlePlanChange = async (
    {
    selectedPlanId,
    currentSubscriptionId,
    newPlanPriceId,
    stripe,
    handleCheckout,
    updateLoadingStatus
    }
) => {
    const headers = getAuthHeadersFromStorage();
    /*attempt to delete cancel current subscription and then poll backend 5 times until
    either maxretries are exceeded or a status of CANCELLED is returned for current subscription
    */
    api.delete(updateOrGetSubscriptionEP(currentSubscriptionId), { headers })
        .then((_) => {
            let retries = 0;
            const interval = setInterval(() => {
                api.get(updateOrGetSubscriptionEP(currentSubscriptionId), { headers })
                    .then((response) => {
                        if (response.data.status === CANCELLED) {
                            clearInterval(interval);
                            handleCheckout(stripe, newPlanPriceId)
                            .then((_) =>{
                                updateLoadingStatus(prev => ({ ...prev, [selectedPlanId]: false }));
                                return;
                            }).catch((error) => {
                                updateLoadingStatus(prev => ({ ...prev, [selectedPlanId]: false }));
                                if (error.status === 400){
                                    toast.info(error.message)
                                } else {
                                    toast.warning(error.message);
                                }
                            })
                        }
                        retries++;

                        if (retries >= MAX_RETRIES) {
                            clearInterval(interval);
                            updateLoadingStatus(prev => ({ ...prev, [selectedPlanId]: false }));
                            onMaxRetriesExceeded();
                        }
                    })
                    .catch(error => {
                        clearInterval(interval);
                        onError(error);  // Corrected from err to error
                    });
            }, 3000); // poll endpoint every three seconds.
        })
        .catch((error) => {
            onError(error);  // Corrected from err to error
        });
};



export const getPlanByName = (name, onSuccess) =>{
    api.get(
        getPlanEp(name)
    ).then(
        (response) => {
            onSuccess(response.data)
        }
    ).catch((error) => {
        toast.error(`failed to fetch plan ${error}`)
    })
}

export const capitalize = (str)  => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const isEmpty = (obj)  => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const getPaymentIntent = () => localStorage.getItem("paymentIntent");
export const removePaymentIntent = () => localStorage.removeItem("paymentIntent");