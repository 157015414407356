import { createSubscriptionEP} from "../constants/endpoints";
import { api, getAPIKey } from "./api";

class CheckOutError extends Error {
    constructor(status, message) {
      super(message);
      this.status = status;
    }
  }

  
  const CreateHandleCheckOut = () => {
    return async (stripe, priceID) => {;
        if (!stripe || !priceID){
            throw new CheckOutError(400, "stripe or priceID Missing")
        }

        let session;

        try {
            session = await fetchCheckout(priceID); // Now session contains the parsed JSON data
        } catch (error) {
            if (!error.response) {
                throw new CheckOutError(500, error);
            } else if (error.response.status === 403) {
                throw new CheckOutError(403, 'Forbidden: Maybe API Key is Invalid');
            } else if (error.response.status === 400){
                throw new CheckOutError(400, error.response.data.info)
            } else {
                throw new CheckOutError(500, "Unhandled error: " + error.message);
            }
            
        }

        if (session) {
            try {
                await stripe.redirectToCheckout({
                    sessionId: session.session_id,
                });
            } catch (error) {
                throw new CheckOutError(500, "stripe redirect failed");
            }
        } else {
            throw new CheckOutError(500, 'stripe session not available');
        }
    }
}



const fetchCheckout = async (priceID) => {
    const apiKey = getAPIKey()
    try {
        if (!apiKey){
            throw Error("API Key not found")
        }
        const response = await api.post(createSubscriptionEP, 
        { stripe_price_id: priceID}, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `APIKEY ${apiKey}`
            }
        });
        return response.data;

    } catch (error) {
        throw new CheckOutError(500, error.response.data.info);
    }

}

export default CreateHandleCheckOut;