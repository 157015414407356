import iso3166 from 'iso-3166-1'
import { useState, useEffect } from 'react';
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Select  from 'react-select'
import "./AppPage.css"

const countryOptions = iso3166.all().map(country => ({
    value: country.alpha2,
    label: country.country
}));

const defaultCountryOption = countryOptions.find(option => option.value === 'US');

function CountrySelect ({ onCountryChange }){
    const [selectedCountry, setSelectedCountry] = useState(defaultCountryOption)
    //initially set to default
    useEffect(() => {
      onCountryChange(defaultCountryOption.value.toLowerCase())
    }, [])

    const handleCountryChange = (option) => {
        setSelectedCountry(option);
        if (option) {
          onCountryChange(option.value.toLowerCase());
        } else {
          onCountryChange('');
        }
    };

    return (
        <div>
          <label className='input-label'>
            Country
            <Tooltip title="Select location of proxies">
              <InfoIcon className="info-icon" fontSize="10px" />
            </Tooltip>
          </label>
          <Select
            options={countryOptions}
            value={selectedCountry}
            onChange={handleCountryChange}
            className="country-select"
            placeholder="Select country..."
            menuPortalTarget={document.body}
          />
        </div>
    )
}

export default CountrySelect;