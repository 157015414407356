import React from 'react';
import Home from "./components/home/Home"
import { ToastContainer} from 'react-toastify';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} newestOnTop={false}
       closeOnClick rtl={false} pauseOnFocusLoss pauseOnHover
       className="custom-toast-container"
       />
        <Home/>
    </div>
  );
}

export default App;
